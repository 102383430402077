import React, { useState } from "react";
import "./loader.css";

class Loader extends React.Component {
  render() {
    return (
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="preloader-circle"></div>
            <div className="preloader-img pere-text">
              <svg
                width="80"
                height="80"
                viewBox="0 0 1114 1115"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M177 275H250L385 711L518.188 283.5H346.188L346.5 263H848.5L847.5 284.5H733V790.5C728 799.167 718.6 813.7 703 822.5H954V834.5H663.5V283.5H630.5V834.5H559.5L559.187 283.5H524.187L351.5 834.5L177 275Z"
                  fill="#252525"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
