import React from "react";
import "./whatWeOffer.css";

const WhatWeOffer = (props) => {
  return (
    <div className="container-fluid">
      <div className="container" id="offer">
        <div className="row">
          <div className="col-lg-7 col-md-12 offer-wrap">
            <h1 data-aos="fade-right">Our Expertise</h1>
            <p data-aos="fade-right">
              At customer satisfaction. From air and sea freight to customs
              clearance, warehousing, and transportation, we ensure smooth cargo
              movement and seamless delivery every step of the way.Velotrans
              Logistics, we provide end-to-end logistics solutions focused on
              efficiency, reliability, and
            </p>

            <div className="tag" data-aos="fade-up">
              <div className="icon">
                <i className="fas fa-plane"></i>
              </div>
              <div className="p-3 description">
                <h4>Air Freight</h4>
                <p>
                  Reliable air freight solutions with competitive rates, fast
                  transit times, and seamless customs clearance to ensure swift
                  deliveries.
                </p>
              </div>
            </div>

            <div className="tag" data-aos="fade-up">
              <div className="icon">
                <i className="fas fa-ship"></i>
              </div>
              <div className="p-3 description">
                <h4>Sea Freight</h4>
                <p>
                  Comprehensive ocean cargo services including FCL, LCL, break
                  bulk, and project forwarding, connecting global ports with
                  tailored logistics support.
                </p>
              </div>
            </div>

            <div className="tag" data-aos="fade-up">
              <div className="icon">
                <i class="far fa-file-alt"></i>
              </div>
              <div className="p-3 description">
                <h4>Custom Clearance</h4>
                <p>
                  Expert customs clearance services to simplify procedures,
                  handle documentation, and ensure smooth import and export
                  processes.
                </p>
              </div>
            </div>

            <div className="tag" data-aos="fade-up">
              <div className="icon">
                <i class="fas fa-boxes"></i>
              </div>
              <div className="p-3 description">
                <h4>Warehousing</h4>
                <p>
                  30,000 sq. ft. of secure, fully managed storage with advanced
                  pick-and-pack systems for short- and long-term needs
                </p>
              </div>
            </div>

            <div className="tag" data-aos="fade-up">
              <div className="icon">
                <i class="fas fa-truck"></i>
              </div>
              <div className="p-3 description">
                <h4>Transportation</h4>
                <p>
                  Tailored transportation solutions for oversized and heavy
                  cargo with a global network, ensuring timely delivery and
                  efficient movement.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 image d-none d-lg-block"
            style={{ backgroundImage: "url(img/image3.jpg)" }}
            data-aos="fade-left"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
