import React from "react";
import "./navBar.css";
import { NavLink } from "react-router-dom";

const NavBar = (props) => {
  let goBack = () => {
    console.log("Go Back triggered");
    props.history.goBack();
  };

  let getRelaventNavBar = () => {
    if (!props.setBackVisible) {
      return (
        <nav className="navbar navbar-expand-lg d-flex" id="navbar">
          <div className="logo-wrapper">
            <div class="main-logo" data-aos="fade-right">
              <a href="/home">
                <img src="./img/logoIcon.svg" alt="Velotrans Logistics" />
                <h1 className="d-none d-sm-none d-md-block">Velotrans</h1>
                <h3 className="d-none d-sm-none d-md-block">Logistics</h3>
              </a>
            </div>
          </div>

          <div className="container" data-aos="fade-left">
            <button
              className="navbar-toggler ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa fa-bars"></i>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/home"
                    activeClassName="active"
                    exact
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/what-we-do">
                    What We Do
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about-us">
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contact">
                    Contact
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/faq">
                    FAQ
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      );
    } else {
      return (
        <nav className="navbar navbar-expand-lg" id="navbar">
          <div className="container">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item nav-link-back" onClick={() => goBack()}>
                <i className="fa fa-arrow-left"></i>
              </li>
            </ul>
          </div>
        </nav>
      );
    }
  };

  return getRelaventNavBar();
};

export default NavBar;
