import React from "react";
import "./whatWeDo.css";

const Services = (props) => {
  const serviceList = [
    {  
      name: "Air Freight",
      description: "Reliable air freight solutions with competitive rates, fast transit times, and seamless customs clearance to ensure swift deliveries.",
      tileImage: "img/image1.jpg",
      viewMore: "http://link.to.main.article/1",
    },
    {
      name: "Sea Freight",
      description:
        "Comprehensive ocean cargo services including FCL, LCL, break bulk, and project forwarding, connecting global ports with tailored logistics support.",
      tileImage: "img/image2.jpg",
      viewMore: "http://link.to.main.article/2",
    },
    {
      name: "Customs Clearance",
      description:
        "Expert customs clearance services to simplify procedures, handle documentation, and ensure smooth import and export processes.",
      tileImage: "img/image3.jpg",
      viewMore: "http://link.to.main.article/3",
    },
    {
      name: "Warehousing",
      description:
        "30,000 sq. ft. of secure, fully managed storage with advanced pick-and-pack systems for short- and long-term needs.",
      tileImage: "img/image4.jpg",
      viewMore: "http://link.to.main.article/4",
    },
    {
      name: "Transportation",
      description:
        "Tailored transportation solutions for oversized and heavy cargo with a global network, ensuring timely delivery and efficient movement.",
      tileImage: "img/image5.jpg",
      viewMore: "http://link.to.main.article/5"
    },
  ];

  const generateTiles = () => {
    let tileList = [];
    let j = 0;
    serviceList.forEach((element) => {
      tileList.push(
        <div 
          className="col-lg-4 col-md-6 col-sm-6" 
          data-aos="fade-up"
        >
          <div className="single-cat text-center mb-50">
            <div className="cat-icon">
              <span className=""></span>
            </div>
            <div className="cat-cap">
              <h5>{element.name}</h5>
              <p>{element.description}</p>
            </div>
          </div>
        </div>
      );
    });
    return tileList;
  };

  return (
    <div className="categories-area pt-100">
      <div className="container">
        <div className="row">{generateTiles()}</div>
      </div>
    </div>
  );
};

export default Services;
