import React, { useState } from "react";
import "./topBar.css";

class TopBar extends React.Component {
  render() {
    return (
      <div className="container-fluid" id="topBar" data-aos="fade-down">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-6 col-lg-7 col-md-4 col-sm-5 col-6">
              <div className="row">
                <div className="col d-none d-md-none d-lg-block text-end email">
                  <i className="fas fa-envelope fa-2x"></i>
                  <p>
                    <span>Email:</span> <a href=":mailto">pathum@vtlsl.com</a>
                  </p>
                </div>
                <div className="col text-end call">
                  <i className="fas fa-phone-alt fa-2x"></i>
                  <p>
                    <span>
                      Call Us:
                      <br className="d-block d-sm-none" />
                    </span>
                    &nbsp;+94 71 772 3456
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-3 d-lg-block d-md-none d-sm-none d-none email">
              <div>
                <i className="fas fa-envelope fa-2x"></i>
              </div>
              <div>
                <p>
                  <span>Email:</span> <a href=":mailto">pathum@vtlsl.com</a>
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-6 call">
              <div>
                <i className="fas fa-phone-alt fa-2x"></i>
              </div>
              <div>
                <p>
                  <span>
                    Call Us:
                    <br className="d-block d-sm-none" />
                  </span>
                  <a href="#"> +94 76 000 0000</a>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
