import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const getYear = () => {
    let date = new Date();
    return date.getFullYear();
  };

  return (
    <div className="container-fluid footer" data-aos="fade-up">
      <div className="container"> 
        <div className="row gx-md-3 gx-lg-5">
          <div className="col-md-4 question">
            <h3>Have a Question?</h3>
            <div className="details">
              <div className="hr">
                <p className="please-contact">Please Contact:</p>
                <div className="icon">
                  <i className="fas fa-user"></i>
                </div>
                <p className="user-name">Pathum</p>
                <p className="user-position">Director - Operations</p>
                <p className="user-email">pathum@vtlsl.com</p>
              </div>
              <div className="icon">
                <i className="fas fa-map-marker"></i>
              </div>
              <p className="address">
                3rd Floor, IBM Building I, Nawam Mawatha, Colombo 02
              </p>
              <div className="icon">
                <i className="fas fa-phone-alt"></i>
              </div>
              <p className="number">
                +94 71 772 3456
                <br />
              </p>
              <div className="icon">
                <i className="fas fa-envelope"></i>
              </div>
              <p className="email">pathum@vtlsl.com</p>
            </div>
          </div>
          <div className="col-md-3 map">
            <div className="gmap_canvas">
              <iframe
                title="Map"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=IBM,+Navam+Mawatha,+Colombo,+Sri+Lanka&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
          <div className="col d-none d-xl-block links">
            <h3>Links</h3>
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/what-we-do">What we do</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
          </div>
          <div className="col contact">
            <h3>Connect With Us!</h3>
            <div className="icons d-flex flex-row-reverse">
              <div className="icon">
                <i className="fab fa-facebook-f"></i>
              </div>
              <div className="icon">
                <i className="fab fa-twitter"></i>
              </div>
              <div className="icon">
                <i className="fab fa-youtube"></i>
              </div>
              <div className="icon">
                <i className="fab fa-instagram"></i>
              </div>
            </div>
          </div>
          <div className="w-100"></div>
          <div className="copyright">
            <p>
              {" "}
              Copyright &copy; {getYear()} All rights reserved 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
