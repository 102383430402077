import React from "react";
import "./aboutUs.css";
//import chip
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

const CourseInfo = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    roots: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

  const classes = useStyles();
  const [chipData] = React.useState([
    { key: 0, label: "IT" },
    { key: 1, label: "Designing" },
    { key: 2, label: "Graphic" },
  ]);

  return (
    <div className="container-fluid courseInfo" data-aos="fade-up" data-aos-delay="300">
      <div className="container">
        <div className="title-wrapper">
          <h1 className="title">
          Who We Are, What We Deliver
          </h1>
          <p className="subtitle">Moving Goods, Moving Boundaries</p>
        </div>
        <div className="row course-description" data-aos="fade-up" data-aos-delay="600">
          <div className="col">
            <img
              src="img/image 24.jpg"
              className="col-lg-5 col-md-4 img-fluid float-start"
              alt="Image"
            />
            <p>
            Founded in 2000, Velotrans Logistics (Pvt) Ltd has established itself as a leading freight forwarder and customs broker in Sri Lanka, renowned for delivering comprehensive and tailored logistics solutions. With a steadfast commitment to excellence, we have grown alongside our clients, adapting to the changing demands of the logistics industry while ensuring reliable, efficient, and cost-effective services that exceed expectations.
            </p>
            <p>
            We offer a wide range of logistics services, including air and sea freight, customs clearance, transshipment, warehousing, transportation, and door-to-door delivery. Our operations are supported by advanced technology and a global network of partners, ensuring smooth cargo movement from origin to destination. Whether handling time-sensitive shipments or large-scale industrial cargo, we are dedicated to meeting deadlines, reducing complexities, and providing a hassle-free experience for our clients.
            </p>
            <p>
            At Velotrans Logistics, customer satisfaction is at the heart of everything we do. We manage every aspect of the logistics process, allowing our clients to focus on their core business activities without worrying about procedural challenges or delays. Our experienced team handles everything from documentation for customs clearance to providing secure storage in our 30,000 sq. ft. warehouse, designed for both short- and long-term needs. Our aim is to deliver seamless experiences with every shipment, ensuring peace of mind for our clients.
            </p>
            <p>
            Our dedicated team of professionals brings years of expertise to every project, ensuring precision, flexibility, and accountability. We pride ourselves on being more than just a logistics provider; we see ourselves as a strategic partner that helps businesses grow by offering solutions tailored to fit their specific needs and challenges.
            </p>
            <p>
            Over the years, we have built a reputation for reliability, transparency, and innovation. Our ability to provide both global reach and local expertise has earned us the trust of businesses across various industries. At Velotrans Logistics, we believe that logistics is more than just moving goods—it’s about building relationships, connecting businesses, and creating value at every step.
            </p>
            <p>
            As we continue to grow, our focus remains on delivering exceptional service that empowers our clients to achieve success in an ever-changing world.
            </p>
          </div>
        </div>

        {/* Our Mission */}
        <div className="modules" data-aos="fade-up">
          <div className="module-title">
            <h3>Our Mission</h3>
          </div>
          <div className="row course-description">
            <div className="col">
              <p className="quote">
              "Our mission is to excel in freight forwarding and Customs House Agency services by delivering fast, secure, and reliable door-to-door cargo solutions. We are committed to maintaining the highest standards of service through the use of cutting-edge technology, ensuring that we meet and exceed customer expectations. By continuously improving our operations, we aim to achieve sustainable profitability while enhancing the working and living conditions of our employees, fostering growth, and upholding our dedication to quality and customer satisfaction."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
