import React from "react";
import "./faq.css";

const FAQ = (props) => {
  const faqDataList = [
    {
      question: " What services does Velotrans Logistics (Pvt) Ltd offer?",
      answer:
        "We offer a comprehensive range of logistics services, including air and sea freight, customs clearance, warehousing, transportation, transshipment, consolidation, and door-to-door delivery. Our services are tailored to meet the specific needs of our clients, ensuring smooth cargo movement from origin to destination.",
    },
    {
      question:
        "What kind of cargo can you transport?",
      answer:
        "You can track your shipment through our online tracking system. Once your shipment is registered, you will receive a tracking number that allows you to monitor its real-time status and location. If you need further assistance, our customer service team is always available to help.",
    },
    {
      question: "What documentation is required for customs clearance?",
      answer:
        "The documents required may vary depending on the type of goods and the country of origin or destination. Generally, you will need an invoice, packing list, bill of lading or airway bill, import/export license, and any applicable permits. Our customs clearance experts will guide you through the process to ensure compliance and smooth clearance.",
    },
    {
      question:
        "Do you offer warehousing services?",
      answer:
        "Yes, our warehouse offers over 30,000 sq. ft. of secure, fully-racked space that accommodates more than 2,000 pallets. We provide both short- and long-term storage solutions, as well as inventory management through a computerized pick-and-pack system.",
    },
    {
      question: "Can you handle oversized or specialized cargo?",
      answer:
        "Yes, we specialize in managing oversized, heavy, or unconventional cargo. Our team provides tailored transportation solutions for project cargo and heavy equipment, including route planning, permits, and specialized equipment handling.",
    },
    {
      question: "How can I contact your team for further assistance?",
      answer:
        "You can reach us via phone, email, or by visiting our office at the IBM Building, Nawam Mawatha, Colombo 02. Our customer service team is happy to assist you with any inquiries or service requests. Alternatively, you can go to our <a href='/contact'>Contact Page</a> and fill out the form to get in touch with us directly."
      },
  ];

  const getIDs = (type, i) => {
    if (type === "accordion-item-id") {
      return "flush-heading" + i;
    } else if (type === "data-bs-target") {
      return "#flush-collapse" + i;
    } else if (type === "aria-controls") {
      if (i === 1) {
        return "flush-collapse" + i;
      } else {
        return "flush-collapse" + (i - 1);
      }
    } else if (type === "flush-collapse-id") {
      return "flush-collapse" + i;
    } else if (type === "aria-labelledby") {
      return "flush-heading" + i;
    }
  };

  const generateFAQs = () => {
    let faqList = [];
    let i = 0;
    faqDataList.forEach((element) => {
      i++;
      faqList.push(
        <div className="accordion-item"  data-aos="fade-up" data-aos-delay={100*i} >
          <h2 className="accordion-header" id={getIDs("accordion-item-id", i)}>
            <button
              className="accordion-button collapsed collapsed-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={getIDs("data-bs-target", i)}
              aria-expanded="false"
              aria-controls={getIDs("aria-controls", i)}
            >
              {element.question}
            </button>
          </h2>
          <div
            id={getIDs("flush-collapse-id", i)}
            className="accordion-collapse collapse"
            aria-labelledby={getIDs("aria-labelledby", i)}
            data-bs-parent="#accordionFlush"
          >
            <div className="accordion-body">{element.answer}</div>
          </div>
        </div>
      );
    });
    return faqList;
  };

  return (
    <div className="pt-100">
      <div className="container">
        <div className="row">
          {/* Accordion */}
          <div className="modules">
            <div className="module-title"  data-aos="fade-up">
              <h3>Frequently Asked Questions</h3>
            </div>
            <div className="accordion" id="accordionFlush">
              {generateFAQs()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
