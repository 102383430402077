import React from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import PageTitle from "./components/pageTitle/pageTitle";
import FAQ from "./components/‌faq/faq";

const InstituteCourseInfo = () => {
  return (
    <div>
      <Header />
      <PageTitle
        title={"Frequently Asked Questions"}
        breadcrumbSteps={["Home", "FAQ"]}
      />
      <FAQ />
      <Footer />
    </div>
  );
};

export default InstituteCourseInfo;
