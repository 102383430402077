import React from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import AboutUs from "./components/aboutUs/aboutUs";
import PageTitle from "./components/pageTitle/pageTitle";

const InstituteCourseInfo = () => {
  return (
    <div>
      <Header />
      <PageTitle title={"Who we are"} breadcrumbSteps={["Home", "About Us"]} />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default InstituteCourseInfo;
