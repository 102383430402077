import React from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import ContactUs from "./components/contactUs/contactUs";
import PageTitle from "./components/pageTitle/pageTitle";

const InstituteContactUs = () => {
  return (
    <div>
      <Header />
      <PageTitle title={"Contact Us"} breadcrumbSteps={["Home", "Contact"]} />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default InstituteContactUs;
