import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "./institute/index";
import Contact from "./institute/contactUs";
import AboutUs from "./institute/aboutUs";
import WhatWeDo from "./institute/whatWeDo";
import FAQ from "./institute/faq";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/what-we-do",
    element: <WhatWeDo />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
]);

export default Router;
