import React from "react";
import TopBar from "../topBar/topBar";
import NavBar from "../navBar/navBar";
import "./header.css";

const Header = () => {
  let getRelaventNavBar = () => {
    return (
      <div id="header">
        <TopBar />
        <NavBar />
      </div>
    );
  };

  return getRelaventNavBar();
};

export default Header;
