import React from "react";
import "./introVideo.css";

const IntroVideo = (props) => {
  return (
    <div
      className="get-quote"
      style={{ backgroundImage: "url(/img/image5.jpg)" }}
    >
      <div className="container-fluid gradient">
        <div className="container">
          <div className="row">
            <div className="quote-wrap">
              <div className="col-6 title">
                <h1>Request a Free Quote</h1>
                <p>Get A Quote For Free</p>
              </div>
              <form>
                <div className="row gx-5">
                  {/* Name */}
                  <div className="col-xl-3 col-lg-8 col-md-10" data-aos="fade-up">
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      id="inputFirstname"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-8 col-md-10" data-aos="fade-up">
                    {/* Email */}
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      id="inputLastname"
                    />
                  </div>
                </div>

                <div className="row gx-5" data-aos="fade-up">
                  {/* Contact Number */}
                  <div className="col-xl-6 col-lg-8 col-md-10">
                    <input
                      type="text"
                      placeholder="Contact Number"
                      className="form-control"
                      id="inputPhone"
                    />
                  </div>
                </div>

                <div className="row gx-5" data-aos="fade-up">
                  {/* Select Services */}
                  <div className="col-xl-3 col-lg-8 col-md-10" data-aos="fade-up">
                    <input
                      type="text"
                      className="form-control"
                      id="Services"
                      placeholder="Select Services"
                    />
                  </div>
                  {/* City of Departure */}
                  <div className="col-xl-3 col-lg-8 col-md-10" data-aos="fade-up">
                    <input
                      type="text"
                      className="form-control"
                      id="City of Departure"
                      placeholder="City of Departure"
                    />
                  </div>
                </div>

                <div className="row gx-5" data-aos="fade-up">
                  {/* Incoterms*/}
                  <div className="col-xl-3 col-lg-8 col-md-10">
                    <input
                      type="text"
                      className="form-control"
                      id="Incoterms"
                      placeholder="Incoterms"
                    />
                  </div>
                  {/* Weight */}
                  <div className="col-xl-3 col-lg-8 col-md-10" data-aos="fade-up">
                    <input
                      type="text"
                      className="form-control"
                      id="Weight"
                      placeholder="Weight"
                    />
                  </div>
                </div>

                <div className="row gx-5" data-aos="fade-up">
                  {/* Volume */}
                  <div className="col-xl-6 col-lg-8 col-md-10">
                    <input
                      type="text"
                      placeholder="Volume "
                      className="form-control"
                      id="Volume "
                    />
                  </div>
                </div>

                <div className="row gx-5" data-aos="fade-up">
                  {/* REQUEST A QUOTE */}
                  <div className="col-12">
                    <button type="submit" className="btn btn-quote">
                      Request a Quote
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroVideo;
