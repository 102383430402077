import React, { useState, useEffect, useRef } from "react";
import "./categories.css";
import "./flaticon.css";

/**
 * A component that displays counters for various categories of freights and cargo.
 *
 * Props:
 * - airFreights (number): the initial count of air freights, default is 8760.
 * - seaFreights (number): the initial count of sea freights, default is 5840.
 * - customClearances (number): the initial count of custom clearances, default is 17520.
 * - personalCargo (number): the initial count of personal cargo, default is 16060.
 *
 * State:
 * - airCount (number): the current count of air freights.
 * - seaCount (number): the current count of sea freights.
 * - customCount (number): the current count of custom clearances.
 * - personalCount (number): the current count of personal cargo.
 * - hasAnimated (boolean): flag to indicate if the counting animation has been triggered.
 *
 * Refs:
 * - sectionRef: reference to the DOM element for the category boxes.
 *
 * Effects:
 * - Sets custom fonts and initializes an IntersectionObserver to start counting
 *   when the component is in view.
 *
 * Methods:
 * - setFonts: loads and applies custom fonts.
 * - startCounting: initiates the counting animation for each category.
 * - animateCount: performs a smooth counting animation from start to end value.
 *
 * Returns:
 * A JSX element containing a grid of boxes, each representing a category with its icon,
 * current count, and description.
 */
const Categories = ({
  airFreights = 8760,
  seaFreights = 5840,
  customClearances = 17520,
  personalCargo = 16060,
}) => {
  const [airCount, setAirCount] = useState(0);
  const [seaCount, setSeaCount] = useState(0);
  const [customCount, setCustomCount] = useState(0);
  const [personalCount, setPersonalCount] = useState(0);
  const [dateCount, setDateCount] = useState(
    Math.floor(
      (new Date().getTime() - Date.parse("25 Nov 2024")) / (1000 * 3600 * 24)
    )
  );

  const airFreightsIncrement = 6;
  const seaFreightsIncrement = 4;
  const customClearancesIncrement = 12;
  const personalCargoIncrement = 13;

  const sectionRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    setFonts();

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setHasAnimated(true);
          startCounting();
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [hasAnimated]);

  const setFonts = () => {
    const fontFace = new FontFace(
      "Flaticon",
      "url(/Fonts/FlaticonMerged.woff2)"
    );

    document.fonts.add(fontFace);

    fontFace.load().then(() => {
      if (sectionRef.current) {
        sectionRef.current.style.fontFamily = "RFDewiExpanded-Thin";
        sectionRef.current.style.fontWeight = 100;
        sectionRef.current.style.fontStyle = "normal";
      }
    });
  };

  const startCounting = () => {
    console.log("Counting started" + airFreights);
    animateCount(
      airCount,
      Number(airFreights) + dateCount * airFreightsIncrement,
      setAirCount
    );
    animateCount(
      seaCount,
      Number(seaFreights) + dateCount * seaFreightsIncrement,
      setSeaCount
    );
    animateCount(
      customCount,
      Number(customClearances) + dateCount * customClearancesIncrement,
      setCustomCount
    );
    animateCount(
      personalCount,
      Number(personalCargo) + dateCount * personalCargoIncrement,
      setPersonalCount
    );
  };

  const animateCount = (start, end, setCount) => {
    let startTimestamp = null;
    const duration = 2000; // animation duration in milliseconds

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * (end - start) + start));

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  return (
    <div ref={sectionRef} className="container-fluid">
      <div className="row" id="category-boxes" ref={sectionRef}>
        <div className="col-6 col-sm-3 redbox">
          <span className="flaticon-plane"></span>
          <h3>{airCount}</h3>
          <p className="d-block">Air Freights</p>
        </div>
        <div className="col-6 col-sm-3 bluebox">
          <span className="flaticon-boat-ship"></span>
          <h3>{seaCount}</h3>
          <p className="d-block">Sea Freights</p>
        </div>
        <div className="col-6 col-sm-3 redbox">
          <span className="flaticon-hand-box"></span>
          <h3>{customCount}</h3>
          <p className="d-block">Custom Clearance</p>
        </div>
        <div className="col-6 col-sm-3 bluebox">
          <span className="flaticon-truck"></span>
          <h3>{personalCount}</h3>
          <p className="d-block">Personal Cargo</p>
        </div>
      </div>
    </div>
  );
};

export default Categories;
