import React from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Services from "./components/servicesList/services";
import PageTitle from "./components/pageTitle/pageTitle";

const WhatWeDoPage = () => {
  return (
    <div>
      <Header />
      <PageTitle
        title={"What we do"}
        breadcrumbSteps={["Home", "What we do"]}
      />
      <Services />
      <Footer />
    </div>
  );
};

export default WhatWeDoPage;
