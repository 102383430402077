import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/js/all.js";
import Router from "./router";
import { RouterProvider } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return <RouterProvider router={Router} />;
};

ReactDOM.render(<App />, document.getElementById("root"));
