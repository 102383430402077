import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./testimonials.css";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const Testimonials = (props) => {
  return (
    <div className="container-fluid testimonials" data-aos="fade-up">
      <div className="container">
        <div className="row col align-self-center justify-content-center ">
          <div className="col-9 align-self-center justify-content-center headder-div">
            <h1>What Our Clients Are Saying</h1>
            <p>
              Explore the success stories of our trusted clients. See how
              Velotrans Logistics has helped businesses thrive with tailored
              solutions that drive growth.
            </p>
          </div>
          <div className="w-100"></div>
          <div className="slider-wrapper">
            <i className="fas fa-quote-left quote"></i>
            <>
              <Swiper pagination={true} navigation={true} className="slider">
                <SwiperSlide className="slide">
                  <div className="row justify-content-center slide-wrap">
                    <div className="col-5 col-sm-3 col-md-3 col-lg-2 slide-img">
                      <img src="img/image 18.jpg" className="img-fluid" />
                    </div>
                    <div className="align-self-center float-end col-10 col-sm-8 col-md-6 col-lg-5 slide-content">
                      <p className="message">
                        Partnering with Velotrans was the best decision we made
                        for our logistics needs. Their commitment to on-time
                        deliveries and personalized service is unmatched. They
                        truly go above and beyond!
                      </p>
                      <h6 className="name">Shanika Perera</h6>
                      <p className="position">Managing Director</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                  <div className="row justify-content-center slide-wrap">
                    <div className="col-5 col-sm-3 col-md-3 col-lg-2 slide-img">
                      <img src="img/image 19.jpg" className="img-fluid" />
                    </div>
                    <div className="align-self-center float-end col-10 col-sm-8 col-md-6 col-lg-5 slide-content">
                      <p className="message">
                        Velotrans transformed our supply chain management! Their
                        reliable service and seamless logistics solutions have
                        been a game-changer for our business. We’ve seen
                        significant improvements in efficiency and delivery
                        times.
                      </p>
                      <h6 className="name">Disuin Zurani</h6>
                      <p className="position">ITMS Solutions</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                  <div className="row justify-content-center slide-wrap">
                    <div className="col-5 col-sm-3 col-md-3 col-lg-2 slide-img">
                      <img src="img/image 20.jpg" className="img-fluid" />
                    </div>
                    <div className="align-self-center float-end col-10 col-sm-8 col-md-6 col-lg-5 slide-content">
                      <p className="message">
                        We rely on Velotrans for all our transportation needs,
                        and they never disappoint. Their attention to detail and
                        proactive approach have made a big difference in our
                        operations. Highly recommended!
                      </p>
                      <h6 className="name">Sulaiman Siddrick</h6>
                      <p className="position">
                        Manager, Step Education Institute
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
