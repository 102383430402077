import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { Link } from "react-router-dom";
import "./carousel.css";

class Carousel extends React.Component {
  bannerList = [
    {
      index: 1,
      title: "From Port to Door, We've Got You Covered",
      description:
        "End-to-end logistics with customs clearance, warehousing, and on-time delivery you can trust.",
      image: "img/banner1.jpg",
      button: {
        availability: true,
        content: "Read More",
        url: "/about-us",
      },
    },
    {
      index: 2,
      title: "Seamless Shipping, Anywhere You Go",
      description:
        "Our air and sea freight services ensure fast, secure, and smooth deliveries worldwide.",
      image: "img/banner2.jpg",
      button: {
        availability: true,
        content: "Explore Our Freight Solutions",
        url: "/What-we-do",
      },
    },
    {
      index: 3,
      title: "Mastering Movement, Uniting Destination",
      image: "img/banner3.jpg",
      button: {
        availability: true,
        content: "Learn More About Our Services",
        url: "/what-we-do",
      },
    },
  ];

  render() {
    return (
      <Slider autoplay={4000} className="carousel" data-aos="zoom-in">
        {this.bannerList.map((element) => (
          <div
            key={element.index}
            className="sliderContent"
            style={{
              background: `url('${element.image}') no-repeat center center`,
            }}
          >
            <div className="inner">
              <h1>{element.title}</h1>
              <p>{element.description}</p>
              {element.button.availability ? (
                <Link to={element.button.url} className="btn">
                  {element.button.content}
                </Link>
              ) : null}
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

export default Carousel;
