import React from "react";
import Header from "./components/header/header";
import Loader from "./components/loader/loader";
import Carousel from "./components/carousel/carousel";
import Categories from "./components/categories/categories";
import WhatWeOffer from "./components/whatWeOffer/whatWeOffer";
import IntroVideo from "./components/introVideo/introVideo";
import Testimonials from "./components/testiamonials/testimonials";
import Footer from "./components/footer/footer";

const InstituteIndex = () => {
  return (
    <div>
      <Header />
      <Carousel />
      <WhatWeOffer />
      <Categories />
      <IntroVideo />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default InstituteIndex;
